:root {

    --simpli-color1_old: #924eff;
    --simpli-color1: #000467;
    --simpli-color2: #0038e3;
    --simpli-color3: #1b0943;
    --simpli-color4: #ff81f1;
    --simpli-color5: #000000;
    --simpli-color6: #000467;
    --simpli-color7: #3BA46F;
    --simpli-color8: #FA454C;
    --simpli-color-gris: #757575;
    --simpli-color-light-pink: #FFDBFB;
    --white: white;
    --pink: #ff2982;

    --blue:#3b86ff;
    --red:#FA454C;
    --orange:#ff933b;
    --green:#3BA46F;


    --simpli-primary: #F8C7EA;
    --simpli-primary2: #F8C7EA;
    --simpli-secondary: #000467;
    --simpli-shadow-btn: #00000033;
    --simpli-titulos: #00022B;
    --simpli-tertiary: #545454;
    --simpli-forms: white;
    --simpli-pink: #BA005C;

}

//cambio marca


.primary-button-log {
    border-radius: 30px;
    border: 1px solid var(--simpli-primary2) !important;
    background: var(--simpli-primary) !important;
    color: var(--simpli-secondary) !important;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.20);

    font-family: "DM Sans" !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    margin-right: 10px;
    min-width: 200px !important;
}

.secondary-button-log {
    border-radius: 30px;
    border: 1px solid var(--simpli-secondary);
    background: var(--white) ;
    color: var(--simpli-secondary);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.20);

    font-family: "DM Sans" !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;

    margin-right: 10px;
}

.label-button {
    color: var(--simpli-secondary) !important;
}


.mat-mdc-input-element {
    color: var(--simpli-titulos);
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    letter-spacing: 0.25px;
}

.mdc-floating-label {
    color: var(--simpli-titulos) !important;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    letter-spacing: 0.25px;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--invalid .mdc-floating-label {
    color: #ba1a1a !important;
}

.mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background {
    border-color: var(--simpli-primary) !important;
    background-color: var(--simpli-primary) !important;
}

.simpli-color-gris {
    color: var(--simpli-secondary);
}

.color-tertiary {
    color: var(--simpli-tertiary) !important;
}

.mat-mdc-form-field-hint {
    color: var(--simpli-tertiary) !important; 
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    letter-spacing: 0.25px;
}

.mat-mdc-form-field-error {
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    letter-spacing: 0.25px;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: var(--simpli-forms) !important;
}



//angular 18 styles


html {
    font-family: "DM Sans" !important;
}

body {
    margin: 0px !important;
    font-family: "DM Sans" !important;
}

router-outlet {
    font-family: "DM Sans" !important;
    
}

.mat-mdc-dialog-actions {
    justify-content: flex-start !important;
}

.mat-mdc-card-title{
    font-size: 17px !important;
}

.mat-mdc-card-subtitle {
    font-size: 15px !important;
}

input {
    font-family: "DM Sans" !important;
    
}

.mat-mdc-dialog-panel {
    max-width: 100% !important;
}

@media (max-width: 1024px){
    .mat-mdc-dialog-panel {
        max-width: fit-content !important;
    }
}

.mat-mdc-dialog-content, .mat-mdc-dialog-title {
    font-family: "DM Sans" !important;
}

h1 {
    font-family: "DM Sans" !important;
    font-size: 22px !important;
    
}

.full-altura {
    max-height: 100% !important;
}

h2 {
    font-size: 18px !important;
}

h3 {
    font-size: 18px !important;
}

h4 {
    font-size: 18px !important;
}

.full-altura {
    max-height: 100% !important;
}

th {
    font-family: "DM Sans" !important;
    
}

tr {
    font-family: "DM Sans" !important;
    
}

table {
    font-family: "DM Sans" !important;
    
}

small {
    font-family: "DM Sans" !important;
    
}

div {
    
    font-family: "DM Sans";
}

.mat-mdc-card {
    padding: 16px !important;
    margin-left: 3px !important;
}

.mat-mdc-paginator-container{
    font-family: "DM Sans" !important;
}

.mini-fab-btn {
    width: 30px !important;
    height: 30px !important;
    line-height: 30px !important;
}

.mat-mdc-dialog-actions {
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.mat.mdc-cell {
    border-bottom-color: #c7c7c7 !important;
    border-top-color: #c7c7c7 !important;
}

th.mat-mdc-header-cell {
    border-bottom-color: #c7c7c7 !important;
}

td.mat-mdc-cell {
    border-bottom-color: #c7c7c7 !important;
    border-top-color: #c7c7c7 !important;
}


.table-responsive-simpli {

    .mat.mdc-cell {
        border-bottom-color: #c7c7c7 !important;
        border-top-color: #c7c7c7 !important;
    }

    .mdc-data-table__cell {
        box-sizing: content-box !important;
    }

    .mat-sort-header-container {
        justify-content: center !important;
    }

    tr.mat-mdc-row, tr.mat-footer-row {
        height: 35px;
    }

    tr.mat-mdc-footer-row {
        height: 35px;
    }

    .mat-mdc-header-row {
        text-align: center;
    }
    
    th.mat-mdc-header-cell, td.mat-mdc-cell, td.mat-mdc-footer-cell .mdc-data-table__header-cell,  .mdc-data-table__cell {
        min-width: 120px;
    }
    
    th.mat-mdc-header-cell {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 135%;
        letter-spacing: 0.02em;
        color: #1B0943;
        text-align: center;
        border-bottom-color: #c7c7c7 !important;
    }

    display: block;
    width: 100%;
    overflow-x: auto;
    .mat-mdc-table {
        width: 100%;
        max-width: 100%;
        margin-bottom: 1rem;
        display: table;
        border-collapse: collapse;
        margin: 0px;
		background: none;
		margin-top: 20px;
    }
    .mat-row,
    .mat-header-row {
        display: table-row;
    }
    .mat-cell,
    .mat-header-cell {
        overflow: hidden;
  
    }

    .no-input {
        text-align: center;
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 135%;
		display: flex;
		align-items: center;
		letter-spacing: 0.02em;
    }

    td {
        text-align: center !important;
    }

    td.mat-mdc-cell {
        border-bottom-color: #c7c7c7 !important;
        border-top-color: #c7c7c7 !important;
    }
}

.table-responsive-simpli2 {

    .mat.mdc-cell {
        border-bottom-color: #c7c7c7 !important;
        border-top-color: #c7c7c7 !important;
    }

    

    .mdc-data-table__cell {
        box-sizing: content-box !important;
    }

    .mat-sort-header-container {
        justify-content: center !important;
    }

    tr.mat-mdc-row, tr.mat-footer-row {
        height: 35px;
    }

    tr.mat-mdc-footer-row {
        height: 35px;
    }

    .mat-mdc-header-row {
        text-align: center;
    }
    
    
    th.mat-mdc-header-cell {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 135%;
        letter-spacing: 0.02em;
        color: #1B0943;
        text-align: center;
        border-bottom-color: #c7c7c7 !important;
    }

    display: block;
    width: 100%;
    overflow-x: auto;
    .mat-mdc-table {
        width: 100%;
        max-width: 100%;
        margin-bottom: 1rem;
        display: table;
        border-collapse: collapse;
        margin: 0px;
		background: none;
		margin-top: 20px;
    }
    .mat-row,
    .mat-header-row {
        display: table-row;
    }
    .mat-cell,
    .mat-header-cell {
        overflow: hidden;
  
    }

    .no-input {
        text-align: center;
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 135%;
		display: flex;
		align-items: center;
		letter-spacing: 0.02em;
    }

    td {
        text-align: center !important;
    }

    td.mat-mdc-cell {
        border-bottom-color: #c7c7c7 !important;
        border-top-color: #c7c7c7 !important;
    }
}

.mdc-button__label {
    
    font-family: "DM Sans" !important;
}

.mat-mdc-select-value {
    font-family: "DM Sans" !important;
    
}

.mdc-list-item__primary-text {
    font-family: "DM Sans" !important;
}

.mdc-tab__text-label {
    font-family: "DM Sans" !important;
    letter-spacing: 0;
}

.simpli-snack {

    .mdc-snackbar__surface {
        background: white !important;
    }

    .mdc-snackbar__label {
        color: var(--simpli-color3) !important;
    }

    .mat-mdc-snack-bar-label {
        font-family: 'Poppins'!important;
        font-style: normal!important;
        font-weight: 600!important;
        font-size: 12px!important;
        line-height: 20px!important;
        letter-spacing: 0.02em!important;
        color: var(--simpli-color3);
    }

    .mdc-button__label {
        font-family: 'Poppins'!important;
        color: var(--simpli-color3) !important;
    }
}

.simpli-snack-error {

    .mdc-snackbar__surface {
        background: #FA454C !important;
    }

    .mdc-snackbar__label {
        color: white !important;
    }

    .mat-mdc-snack-bar-label {
        font-family: 'Poppins'!important;
        font-style: normal!important;
        font-weight: 600!important;
        font-size: 12px!important;
        line-height: 20px!important;
        letter-spacing: 0.02em!important;
        color: var(--simpli-color3);
    }

    .mdc-button__label {
        font-family: 'Poppins'!important;
        color: var(--simpli-color3) !important;
    }
}

.simpli-snack-alert {

    .mdc-snackbar__surface {
        background: #FDEB8F !important;
    }

    .mdc-snackbar__label {
        color: #1B0943 !important;
    }

    .mat-mdc-snack-bar-label {
        font-family: 'Poppins'!important;
        font-style: normal!important;
        font-weight: 600!important;
        font-size: 12px!important;
        line-height: 20px!important;
        letter-spacing: 0.02em!important;
        color: #1B0943;
    }

    .mdc-button__label {
        font-family: 'Poppins'!important;
        color: #1B0943 !important;
    }
}

.simpli-snack-cesion {

    .mdc-snackbar__surface {
        background: #EEF2FF !important;
    }

    .mdc-snackbar__label {
        color: #1B0943 !important;
    }

    .mat-mdc-snack-bar-label {
        font-family: 'Poppins'!important;
        font-style: normal!important;
        font-weight: 600!important;
        font-size: 12px!important;
        line-height: 20px!important;
        letter-spacing: 0.02em!important;
        color: #1B0943;
    }

    .mdc-button__label {
        font-family: 'Poppins'!important;
        color: #1B0943 !important;
    }
}

.simpli-snack-success {

    .mdc-snackbar__surface {
        background: #3BA46F !important;
    }

    .mdc-snackbar__label {
        color: white !important;
    }

    .mat-mdc-snack-bar-label {
        font-family: 'Poppins'!important;
        font-style: normal!important;
        font-weight: 600!important;
        font-size: 12px!important;
        line-height: 20px!important;
        letter-spacing: 0.02em!important;
        color: white;
    }

    .mdc-button__label {
        font-family: 'Poppins'!important;
        color: white;
    }
}

.mat-mdc-select-min-line {
    font-family: "DM Sans" !important;
    font-size: 13px !important;
}

.mat-mdc-form-field
{
    width: 100%;
    font-family: "DM Sans" !important;
    margin-top: 10px;
    font-size: 13px !important;
}

.mdc-label {
    font-family: "DM Sans" !important;
}

.mdc-button--outlined {
    width: 200px;
    margin-right: 10px;
}

.mdc-floating-label {
    font-family: "DM Sans" !important;
    font-size: 13px !important;
}

.mat-mdc-form-field-hint {
    font-family: "DM Sans" !important;
    font-size: 11px !important;
    
}

.mat-step-text-label {
    font-family: "DM Sans" !important;
}

.mat-mdc-dialog-container::-webkit-scrollbar {
    height:6px;
    background-color: #F5F5F5;
    width: 5px;
} 
    
.mat-mdc-dialog-container::-webkit-scrollbar-thumb {
    background-color: #7b7b7b;
    border-radius: 5px;
}
    
    
.mat-mdc-dialog-container::-webkit-scrollbar-track {
    background-color: transparent;
}

.mat-mdc-menu-panel  {
    min-width: 112px !important;
    max-width: 400px !important;
    overflow: auto !important;
    -webkit-overflow-scrolling: touch !important;
    max-height: calc(100vh - 48px) !important;
    border-radius: 4px !important;
    outline: 0 !important;
    

}

.mdc-tooltip__surface {
    font-family: "DM Sans" !important;
    font-size: 14px !important;
}

.mat-mdc-dialog-content::-webkit-scrollbar {
    height: 5px;
    background-color: #f5f5f5;
    width: 8px !important;
}

.mat-mdc-dialog-content::-webkit-scrollbar-thumb {
    background-color: #959496;
    border-radius: 5px;
}

.mat-mdc-dialog-content::-webkit-scrollbar-track {
    background-color: transparent;
}

.mat-mdc-menu-panel.menu-user-simpli { 
    width: 400px; 
}

.mat-mdc-menu-panel.menu-user-simpli-detail { 
    width: 300px; 
}

.mat-select-min-line {
    font-family: "DM Sans" !important;
}

.mat-mdc-select-panel {
    min-width: 400px;
}

.mdc-data-table__cell {
    text-overflow: clip !important;
}

.mat-mdc-menu-item-text {
    font-family: "DM Sans" !important;
}

.mat-mdc-dialog-actions {
    margin-bottom: 10px !important;
}

.outer-dot {
    width: 17px  !important;
    height: 26px !important;
    right: -17px  !important;
    top: -14px  !important;
    background: #ddccfb !important;
    border: solid 1px lightgrey;
}

.inner-dot {

    top: -7px!important;
    left: -7px!important;
    border: none!important;
    background: #673ab7!important;
    width: 15px!important;
    height: 15px!important;

}

//end

.col-top
{
    margin-top: 10px;
    margin-bottom: 5px;
}

tr.mat-header-row {
    height: 35px !important;
}

.velo-inactivo
{
    position: absolute;
    top: 22;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffffbf;
    z-index: 9999;
}


/*SIMPLI VAR*/

.subtitulo-simpli{
    font-size: 15px !important;
    color: gray;
    font-weight: 400 !important;
    margin-top: -14px;
    display: block;
    font-family: "DM Sans" !important;

}
  
.titulo-simpli{
    font-weight: 600 !important;
    color: var(--simpli-color3);
    font-size: 29px !important;
    font-style: normal;
    font-family: "Sofia Sans Extra Condensed" !important;
    margin-bottom: 10px !important;
}

.titulo-simpli-new {
    font-family: 'Poppins' !important;
    font-style: normal !important;;
    font-weight: 600 !important;;
    font-size: 20px !important;;
    line-height: 125% !important;;
    letter-spacing: 0.01em !important;;
    color: #1B0943 !important;;
}

.subtitulo-simpli-new {
    font-family: 'Poppins' !important;;
    font-style: normal !important;;
    font-weight: 400 !important;;
    font-size: 15px !important;;
    line-height: 135% !important;;
    letter-spacing: 0.02em !important;;
}

.legend-color {
    border-left: solid 5px #433da9;
}

.label-legend {
    margin-left: 10px;
}

.legend-container {

    font-size: 11px;
    color: gray;
    font-weight: lighter;
    font-family: "DM Sans";
    border: solid 1px #ebebeb;
    width: 240px;
    padding: 15px;
    border-radius: 5px;

}

/*diameter 30 como INPUT*/
.spinner-simpli {
    margin:0 auto;
    margin-top: 20px;
    stroke: #ff81f1;
}

.simpli-snack {
    background: white;
    color: var(--simpli-color3);
    font-weight: bold;
    font-family: "titulos";
}



.mat-option {
    font-family: "DM Sans" !important;
}

@media (max-width:700px) {



    .mat-dialog-actions {
        margin-top:20px !important;
        margin-bottom: 20px !important;
    }

}


.row_main
{
    width: 100%;
    overflow-x: hidden;
    padding: 1%;
    margin: 0;
    padding-top: 0px;
    padding-bottom: 0;
}

.info-msg,
.success-msg,
.warning-msg,
.error-msg {
    margin: 10px 0;
    padding: 10px;
    border-radius: 3px 3px 3px 3px;
    font-size: 14px;
    font-family: 'Poppins';
}

.info-msg {
    color: #059;
    background-color: #BEF;
}

.success-msg {
    color: #270;
    background-color: #DFF2BF;
}

.warning-msg {
    color: #9F6000;
    background-color: #FEEFB3;
}

.error-msg {
    color: #D8000C;
    background-color: #FFBABA;
}

.mat-form-field
{
    width: 100%;
    font-family: "DM Sans" !important;
    margin-top: 10px;
}


.mat-radio-button {

    font-family: "DM Sans" !important;
}

.mat-dialog.title {
    font: inherit !important;
}

.no-input {
    width: 100%;
    border: none;
    background: none;
    box-shadow: none;
    font-family: "DM Sans" !important;
}



.no-input:focus-visible {
    border: none !important;
    outline: none !important;
}

.sinRegistros {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px !important;
    color: #7e7e7e;
    font-family: "DM Sans";
    width: 100%;
    min-height: 150px;
    background: #f7f7f7;
    border-radius: 5px;
  }

.mat-menu-panel  {
    min-width: 112px !important;
    max-width: 400px !important;
    overflow: auto !important;
    -webkit-overflow-scrolling: touch !important;
    max-height: calc(100vh - 48px) !important;
    border-radius: 4px !important;
    outline: 0 !important;

}

.add_factoring::-webkit-scrollbar {
    height:6px;
    background-color: transparent;
    width: 6px;
    } 
        
.add_factoring::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 5px;
}
                    
.add_factoring::-webkit-scrollbar-track {
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
    background-color: transparent;
}

.invisible-scroll::-webkit-scrollbar {
    height:8px;
    background-color: #e8e3e300;
    width: 6px;
    } 
        
.invisible-scroll::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 5px;
}
                    
.invisible-scroll::-webkit-scrollbar-track {
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
    background-color: #e8e3e300;
}

.mat-tooltip {
    font-family: "DM Sans" !important;
    font-size: 14px !important;
}

.mat-dialog-content::-webkit-scrollbar {
    height: 5px;
    background-color: transparent;
    width: 8px !important;
  }
  
  .mat-dialog-content::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 5px;
  }
  
  .mat-dialog-content::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .mat-simple-snackbar-action {
    color: #673ab7 !important;
  }

.stroked-btn{
    width: 200px;
    margin-left: 10px !important;
}

.highcharts-credits {
    display: none !important;
}

.mat-stroked-button {
    font-family: "DM Sans";
}

.tox-statusbar__branding {
    display: none !important;
}

.tox-editor-container {
    font-family: "DM Sans" !important;
}

.simpli-snack-success {
    background: #3BA46F;
    color: white;

    .mat-simple-snack-bar-content {
        font-family: 'Poppins'!important;
        font-style: normal!important;
        font-weight: 400!important;
        font-size: 14px!important;
        line-height: 20px!important;
        letter-spacing: 0.02em!important;
    }

    .mat-simple-snackbar-action {
        font-family: 'Poppins'!important;
        color: white !important;
    }
}

.mat-focus-indicator, .mat-button, .mat-button-base {
    font-family: "DM Sans" !important;
}

.tox-notifications-container {
    display: none !important;
}

.foo .mat-dialog-container  {
    padding: 0;
}

.dialogo-lateral .mat-mdc-dialog-container .mdc-dialog__surface{
    background: #f4edff !important;
    box-shadow: none !important;
}

.tox-notification--error {
    display: none !important;
    visibility: hidden !important;
}

.tox-notification--in {
    display: none !important;
    visibility: hidden !important;
}

.tox-notification--warning {
    display: none !important;
    visibility: hidden !important;
}

.agregar-email-sheet {
    width: 500px;
}

.mat-sm .mat-mdc-form-field  {
    height: 30px !important;
    line-height: 30px !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
 }
 .mat-sm .mat-mdc-text-field-wrapper {
    height: 30px !important;
    line-height: 30px !important;
 }  

 .mat-sm .mat-mdc-form-field-flex {
    height: 30px !important;
    line-height: 30px !important;
 }  

 .mat-sm .mat-mdc-form-field-infix {
    padding: 0 !important;
 }  

 .mat-sm .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
 }

 .td-wd th.mat-mdc-header-cell, td.mat-cell, td.mat-footer-cell {
    width: 20px !important;
    max-width: 20px !important;
    min-width: 20px !important;
 }

.mdc-evolution-chip__text-label {
    font-family: "DM Sans" !important;
}

.mdc-dialog__title::before {

    height: auto !important;
}


mat-card {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15) !important;
}


.dialogo-full-screen-responsive  .mat-mdc-dialog-container .mdc-dialog__surface{
    width: 100%;
    height: 100%;
    box-shadow: none !important;

    .mat-mdc-dialog-content {
        max-height: 75vh !important;
    }
}


mat-card-header {
    justify-content: right;
    margin-bottom: 20px;
    padding-top: 0 !important;

}

mat-card-subtitle{
    font-family: "DM Sans" !important;
    text-align: right;
}

mat-card-title {
    font-family: "DM Sans" !important;
    text-align: right;
    color:#1b0943;
}


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
